import classnames from 'classnames'
import { Row, Col, ListGroup, Badge, Button, FormControl } from 'react-bootstrap'
import Icon from '../utils/Icon'
import ColAuto from '../utils/ColAuto'
import { formatNumber, isRunningOnApp, canCallNative, callNative, showAttendance } from '../../utils'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'

export default function Sidebar({
  apiUrl,
  affiliateCode,
  user,
  loadUser,
  page,
  championships,
  gamesLength,
  sport,
  selectedChampionship,
  show,
  onHide,
  onItemClick,
  logout,
  isDarkTheme,
  settings,
  loadSettings,
  showRules,
  showBetRules,
  setLoading
}) {
  const isApp = isRunningOnApp()
  const [code, setCode] = useState('')

  const refresh = () => {
    setLoading(true)
    loadUser()
    loadSettings()
  }

  const renderAccountElement = () => {
    if (user.nivel != 2 && user.nivel != 3) {
      return (
        <ListGroup.Item
          as="a"
          key="my-account"
          variant={isDarkTheme ? 'dark' : ''}
          href={`${apiUrl}/painel/`}
        >
          <Icon name="user" margin />Minha conta
        </ListGroup.Item>
      )
    }

    return (
      <ListGroup.Item
        key="my-account"
        variant={isDarkTheme ? 'dark' : ''}
        active={page === 'account'}
        onClick={() => onItemClick('account')}
      >
        <Icon name={user.nivel === 2 ? 'coins' : 'user'} margin />
        {user.nivel === 2 ? 'Caixa' : 'Minha conta'}
      </ListGroup.Item>
    )
  }

  const renderLogged = () => {
    if (user) {
      return (
        <>
          {user.nivel == 2 &&
            <Row className="mb-2">
              <Col className={classnames(['text-left text-truncate', { 'text-white': isDarkTheme }])}>
                <Icon style="far" name="dollar-sign" margin /> Crédito
              </Col>
              <Col className={classnames(['text-left text-truncate', { 'text-white': isDarkTheme }])}>
                R$ {formatNumber(user.credito)}
              </Col>
            </Row>
          }
          <Row>
            <Col className={classnames(['text-left text-truncate', { 'text-white': isDarkTheme }])}>
              <Icon style="far" name="dollar-sign" margin /> Saldo
            </Col>
            <Col className={classnames(['text-left text-truncate', { 'text-white': isDarkTheme }])}>
              R$ {formatNumber(user.saldo)}
            </Col>
          </Row>
          {user.nivel === 3 && user.saldo_bonus > 0 && <Row>
            <Col className={classnames(['text-left text-truncate', { 'text-white': isDarkTheme }])}>
              <Icon style="far" name="dollar-sign" margin /> Bônus
            </Col>
            <Col className={classnames(['text-left text-truncate', { 'text-white': isDarkTheme }])}>
              R$ {formatNumber(user.saldo_bonus)}
            </Col>
          </Row>}
        </>
      )
    }
  }

  const renderChampionshipList = (championships) => {
    const countryList = []

    championships.map(championship => {
      if (!countryList[championship.country])
        countryList[championship.country] = {
          flag: championship.matches.length > 0 && championship.matches[0].bandeira_pais == null ? `/images/intl-${isDarkTheme ? 'light' : 'dark'}.png` : championship.flag,
          name: championship.country,
          championships: []
        }

      countryList[championship.country].championships.push(championship)
    })

    return Object.values(countryList).map(country => (
      <ListGroup className="text-truncate" key={country.name}>
        <Row>
          <div className='col-12'>
            <ListGroup.Item className={classnames(['text-truncate country-name', { 'dark': isDarkTheme }])}>
              <img src={country.flag}
                width="20" className="mb-1 mr-3 " />{country.name}
            </ListGroup.Item>
          </div>
          <div className="w-100"></div>
          <div className="col-12">
            <ListGroup className="list-group list-group-flush">
              {country.championships.map(championship => (
                <ListGroup.Item key={championship.name} className='pl-1' variant={isDarkTheme ? 'dark' : ''} onClick={() => onItemClick('sports', championship)}>
                  <Row>
                    <Col className="text-truncate"><Icon name="trophy ml-3 mr-3" />{championship.name}</Col>
                    <ColAuto className="col-auto"><Badge variant='primary'>{championship.matches.length}</Badge></ColAuto>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Row>
      </ListGroup>
    ))
  }
  const renderLogin = () => {
    if (!user) {
      return (
        <ListGroup className="flex-row ">
          <Button className="button-login-sign border-right rounded-0 py-2" variant="primary" onClick={() => onItemClick('login')}>
            <Icon name="sign-in-alt" />
            <br />
            Entrar
          </Button>
          {settings.modulo_apostador && settings.cadastrar_apostador &&
            <Button className="button-login-sign  rounded-0 py-2" variant="primary" onClick={() => onItemClick('register')}>
              <Icon name="user-plus" />
              <br />
              Criar conta
            </Button>}
        </ListGroup>
      )
    }
  }

  return (
    <div className={classnames('sidebar', { 'sidebar-show': show })}>
      <div className="sidebar-backdrop" onClick={onHide}></div>
      <div className="sidebar-content">
        {!user && (settings.habilitar_atendimento || settings.instagram) &&
          <ListGroup className="flex-row ">
            {settings.habilitar_atendimento &&
              <Button block className={classnames(['button-social-media', { 'text-white': isDarkTheme }])} variant="outline" onClick={() => showAttendance(settings)}>
                <Icon name="whatsapp" style="fab" size="2x" />
              </Button>
            }
            {settings.instagram &&
              <Button block className={classnames(['button-social-media m-0', { 'text-white': isDarkTheme }])} variant="outline" onClick={() => window.open(`https://www.instagram.com/${settings.instagram}`)}>
                <Icon name="instagram" style="fab" size="2x" />
              </Button>}
          </ListGroup>
        }
        <ListGroup variant="flush">
          {renderLogin()}
          {user &&
            <ListGroup.Item className="list-group-item-header">
              <div className="text-center">
                <Icon style="fad" name="user-circle" className="display-4" />
                <p className="mb-0">Olá, {(user && user.nome)}</p>
                <hr />
              </div>
              {renderLogged()}
            </ListGroup.Item>
          }
          <ListGroup.Item className="list-group-item-header">
            <Icon name="hashtag" margin />
            Menu Principal
            <Icon name="sync-alt" className="float-right" margin onClick={() => refresh()} />
          </ListGroup.Item>
          <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} active={page === 'sports' && selectedChampionship === null} onClick={() => onItemClick('sports')}>
            <Row>
              <Col className="text-truncate">
                <Icon name="fire" margin /> {sport === 'casino' ? 'Jogos' : 'Eventos'}
              </Col>
              <ColAuto>
                <Badge variant="primary">{sport === 'casino' ? gamesLength : championships.reduce((c, x) => c + x.matches.length, 0)}</Badge>
              </ColAuto>
            </Row>
          </ListGroup.Item>
          {user && !isApp && championships.length > 0 &&
            <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} active={page === 'table'} onClick={() => onItemClick('table')}>
              <Icon name="print" margin /> Tabela
            </ListGroup.Item>
          }
          {user && ((Object.keys(settings?.esportes) || []).length > 0 || !!settings.habilitar_ao_vivo) &&
            <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} active={page === 'bets'} onClick={() => onItemClick('bets')}>
              <Icon name="receipt" margin /> Apostas
            </ListGroup.Item>
          }
          {user && user.nivel != 3 && settings.habilitar_pre_bilhete &&
            <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} onClick={() => onItemClick('validate')}>
              <Icon name="check" margin /> Validar Bilhete
            </ListGroup.Item>
          }
          <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} onClick={() => showRules()}>
            <Icon name="book" margin /> {settings.titulo_regras || 'Regras'}
          </ListGroup.Item>
          <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} onClick={() => showBetRules()}>
            <Icon name="shield-alt" margin /> {settings.titulo_ajuda || 'Ajuda'}
          </ListGroup.Item>
          {user && settings.habilitar_atendimento &&
            <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} onClick={() => showAttendance(settings)}>
              <Icon style="fab" name="whatsapp" margin /> Atendimento
            </ListGroup.Item>
          }
          {user && user.nivel == 2 && canCallNative('setupPrinter') &&
            <ListGroup.Item variant={isDarkTheme ? 'dark' : ''} onClick={() => callNative('setupPrinter')}>
              <Icon name="print" margin /> Config. de impressão
            </ListGroup.Item>
          }
          {!isApp && isMobile && settings.nome_apk &&
            <ListGroup.Item as="a" variant={isDarkTheme ? 'dark' : ''} href={`${settings.nome_apk}`}>
              <Icon style="fab" name="android" margin /> Baixar Aplicativo
            </ListGroup.Item>}
          {settings.habilitar_busca_bilhete && <>
            <ListGroup.Item className="list-group-item-header">
              <Row>
                <Col className="text-truncate">
                  <Icon name="ticket" margin /> Ver Bilhete
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item variant={isDarkTheme ? 'dark' : ''}>
              <Row>
                <Col className="col-8 pr-0">
                  <FormControl type="text" className={classnames(['', { 'dark': isDarkTheme }])} placeholder="Código do Bilhete" value={code} onChange={({ target }) => setCode(target.value)} />
                </Col>
                <Col className="col-4">
                  <Button className=" btn-block" type='button' onClick={() => onItemClick('ticket', code)}>
                    <Icon name="search" />
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          </>}
          {user && [
            <ListGroup.Item key="account-header" className="list-group-item-header">
              <Icon name="user" margin /> Conta
            </ListGroup.Item>,
            renderAccountElement(),
            <ListGroup.Item as="a" key="logout" variant={isDarkTheme ? 'dark' : ''} onClick={() => logout()}>
              <Icon name="sign-out-alt" margin /> Sair
            </ListGroup.Item>
          ]}
          {championships.length > 0 &&
            <>
              <ListGroup.Item className="list-group-item-header">
                <Row>
                  <Col className="text-truncate">
                    <Icon name="trophy" margin /> Competições
                  </Col>
                </Row>
              </ListGroup.Item>
              {renderChampionshipList(championships)}
            </>
          }
        </ListGroup>
      </div>
    </div>
  )
}
